// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
}
.profile-items img {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  margin-bottom: 20px;
}
.profile-items .left,
.profile-items .right {
  border: 1px solid #ced4da;
  text-align: center;
  padding: 30px 30px;
}
.profile-items .left .card {
  margin-bottom: 10px;
}
.profile-items .left ul {
  text-align: left;
}
.profile-items .left ul b {
  margin-right: 5px;
}
.single-customer .btn-delete {
  background: #ff0000;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  padding: 7px 45px;
  transition: 0.5s;
  border: 0;
  display: inline-block;
}
.profile-items .right a {
  display: inline-block;
  margin: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/component/customer/customer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;;EAEE,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,SAAS;EACT,qBAAqB;AACvB;AACA;EACE,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".profile-items {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  column-gap: 2rem;\n}\n.profile-items img {\n  width: 200px;\n  height: 200px;\n  border-radius: 100%;\n  margin-bottom: 20px;\n}\n.profile-items .left,\n.profile-items .right {\n  border: 1px solid #ced4da;\n  text-align: center;\n  padding: 30px 30px;\n}\n.profile-items .left .card {\n  margin-bottom: 10px;\n}\n.profile-items .left ul {\n  text-align: left;\n}\n.profile-items .left ul b {\n  margin-right: 5px;\n}\n.single-customer .btn-delete {\n  background: #ff0000;\n  border-radius: 5px;\n  color: #fff;\n  cursor: pointer;\n  font-size: 15px;\n  padding: 7px 45px;\n  transition: 0.5s;\n  border: 0;\n  display: inline-block;\n}\n.profile-items .right a {\n  display: inline-block;\n  margin: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
