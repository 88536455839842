// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
}
.login-form {
  width: 400px;
  margin: 0 auto;
  padding: 50px 35px;
  box-shadow: 0 0 20px 2px #1a171780;
  border-radius: 5px;
  margin-top: 150px;
}
.login-form form {
  width: 100%;
}
.login-form input {
  padding: 10px 10px;
  width: 100%;
  margin: 10px 0;
  outline: 0;
  border: 1px solid #ddd;
}
`, "",{"version":3,"sources":["webpack://./src/component/login/login.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,OAAO;EACP,QAAQ;EACR,MAAM;EACN,SAAS;EACT,aAAa;AACf;AACA;EACE,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,kCAAkC;EAClC,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,cAAc;EACd,UAAU;EACV,sBAAsB;AACxB","sourcesContent":[".login {\n  background: #fff;\n  position: fixed;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  z-index: 9999;\n}\n.login-form {\n  width: 400px;\n  margin: 0 auto;\n  padding: 50px 35px;\n  box-shadow: 0 0 20px 2px #1a171780;\n  border-radius: 5px;\n  margin-top: 150px;\n}\n.login-form form {\n  width: 100%;\n}\n.login-form input {\n  padding: 10px 10px;\n  width: 100%;\n  margin: 10px 0;\n  outline: 0;\n  border: 1px solid #ddd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
