// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-nav {
  position: fixed;
  top: 0;
  left: 250px;
  z-index: 999;
  background: #1665a0;
  width: calc(100% - 250px);
  height: 70px;
  line-height: 70px;
}

.top-nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-nav-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.top-nav-right ul {
  float: right;
  list-style-type: none;
  margin-bottom: 0;
}
.top-nav-right ul li {
  display: inline;
}
.top-nav-right ul li a {
  font-weight: normal;
  color: #fff;
  font-size: 20px;
  margin-right: 20px;
  transition: 0.5s;
  display: inline;
  padding: 0px 7px;
  padding-top: 6px;
  border-radius: 100%;
}
.top-nav-right ul li a:hover {
  color: #fff;
  background: #44bd32;
}
.topnav-profile-img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  align-items: center;
  justify-content: flex-end;
  object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/component/common/topnav/topnav.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,qBAAqB;EACrB,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,mBAAmB;EACnB,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":[".top-nav {\n  position: fixed;\n  top: 0;\n  left: 250px;\n  z-index: 999;\n  background: #1665a0;\n  width: calc(100% - 250px);\n  height: 70px;\n  line-height: 70px;\n}\n\n.top-nav-wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.top-nav-right {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n.top-nav-right ul {\n  float: right;\n  list-style-type: none;\n  margin-bottom: 0;\n}\n.top-nav-right ul li {\n  display: inline;\n}\n.top-nav-right ul li a {\n  font-weight: normal;\n  color: #fff;\n  font-size: 20px;\n  margin-right: 20px;\n  transition: 0.5s;\n  display: inline;\n  padding: 0px 7px;\n  padding-top: 6px;\n  border-radius: 100%;\n}\n.top-nav-right ul li a:hover {\n  color: #fff;\n  background: #44bd32;\n}\n.topnav-profile-img img {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  display: inline-block;\n  align-items: center;\n  justify-content: flex-end;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
