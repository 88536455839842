// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-card {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.dashboard-cards a {
  border-radius: 5px;
  transition: 0.5s;
}
.dashboard-cards a:hover {
  box-shadow: 0px 0px 10px 3px #ababab;
}
.dashboard-cards a {
  background: #44bd32;
}
.card-content h4 {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.card-content span {
  color: #fff;
  font-size: 32px;
}

.card-icon {
  font-size: 32px;
  font-weight: 400 !important;
  color: #fff;
  border: 3px solid #fff;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  text-align: center;
  padding: 9px 0px;
}

.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/component/dashboard/dashboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,oCAAoC;AACtC;AACA;EACE,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,2BAA2B;EAC3B,WAAW;EACX,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".single-card {\n  padding: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  cursor: pointer;\n}\n.dashboard-cards a {\n  border-radius: 5px;\n  transition: 0.5s;\n}\n.dashboard-cards a:hover {\n  box-shadow: 0px 0px 10px 3px #ababab;\n}\n.dashboard-cards a {\n  background: #44bd32;\n}\n.card-content h4 {\n  color: #fff;\n  font-size: 16px;\n  font-weight: 400;\n}\n\n.card-content span {\n  color: #fff;\n  font-size: 32px;\n}\n\n.card-icon {\n  font-size: 32px;\n  font-weight: 400 !important;\n  color: #fff;\n  border: 3px solid #fff;\n  border-radius: 100%;\n  width: 70px;\n  height: 70px;\n  text-align: center;\n  padding: 9px 0px;\n}\n\n.dashboard-cards {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  column-gap: 30px;\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
